<template>
  <div>
    <div v-if="viewBar" class="EditToolbar">
      <div class="overflow-x">
        <button class="btn toorbarBtn" @click="view_normal()">
          <div><i class="fas fa-expand"></i></div>
          <div>常规视角</div>
        </button>
        <button class="btn toorbarBtn" @click="view_fisheye()">
          <div><i class="fas fa-expand"></i></div>
          <div>鱼眼视角</div>
        </button>
        <button class="btn toorbarBtn" @click="view_stereographic()">
          <div><i class="fas fa-expand"></i></div>
          <div>立体视角</div>
        </button>
        <button class="btn toorbarBtn" @click="view_architectural()">
          <div><i class="fas fa-expand"></i></div>
          <div>构架视角</div>
        </button>
        <button class="btn toorbarBtn" @click="view_pannini()">
          <div><i class="fas fa-expand"></i></div>
          <div>超广角视角</div>
        </button>
        <button class="btn toorbarBtn" @click="view_littleplanet()">
          <div><i class="fas fa-expand"></i></div>
          <div>小行星视角</div>
        </button>
      </div>
    </div>
    <div class="EditToolbar" style="pointer-events:auto">
      <div class="space"></div>
      <button class="btn toorbarBtn back" style="" @click="cancel">
        <div><i class="fas fa-chevron-left"></i></div>
      </button>
      <button class="btn toorbarBtn" :class="{active:viewBar}" @click="viewBar=!viewBar">
        <div><i class="fas fa-expand"></i></div>
        <div>视角</div>
      </button>
      <button class="btn toorbarBtn" @click="resetPanoView">
        <div><i class="fas fa-reply"></i></div>
        <div>复位</div>
      </button>
      <button class="btn toorbarBtn" @click="savePanoCover">
        <div><i class="fas fa-save"></i></div>
        <div>保存全景封面</div>
      </button>
      <button class="btn toorbarBtn" @click="saveTourCover">
        <div><i class="fas fa-save"></i></div>
        <div>保存漫游封面</div>
      </button>
    </div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        viewBar: false,
      }
    },
    watch: {
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      pano() {
        return this.publicData.pano || {}
      },
      form() {
        return this.publicData.form || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          PanoCoverChange: {
            target: 'Data',
            name: 'setPanoCover',
          },
          TourCoverChange: {
            target: 'Data',
            name: 'setTourCover',
          },
        },
        funcs: {
          resetPanoView: this.resetPanoView,
          savePanoCover: this.savePanoCover
        }
      })
    },
    destroyed() {
    },
    methods: {
      resetPanoView() {
        this.getFunc({
          target: 'FullPano',
          name: 'tweenView'
        })({
          fov: this.pano.fov,
          hlookat: this.pano.hLookAt,
          vlookat: this.pano.vLookAt,
        })
        this.view_normal()
      },
      savePanoCover() {
        this.getFunc({ target: 'FullPano', name: 'makeScreenshot' })(this.UploadPanoCover)
      },
      saveTourCover() {
        this.getFunc({ target: 'FullPano', name: 'makeScreenshot' })(this.UploadTourCover)
      },
      UploadPanoCover(blob) {
        this.UpdateCover(blob, this.urls.panoCover({ panoId: this.pano.guid }), 'PanoCoverChange')
      },
      UploadTourCover(blob) {
        this.UpdateCover(blob, this.urls.tourCover({ tourId: this.form.guid }), 'TourCoverChange')
      },
      cancel() {
        this.getFunc(
          {
            name: 'renewSence',
            arg: 'default'
          }
        )()
      },
      view_normal() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_normal'
            }
          }
        )()
      },
      view_fisheye() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_fisheye'
            }
          }
        )()
      },
      view_stereographic() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_stereographic'
            }
          }
        )()
      },
      view_architectural() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_architectural'
            }
          }
        )()
      },
      view_pannini() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_pannini'
            }
          }
        )()
      },
      view_littleplanet() {
        this.getFunc(
          {
            target: 'FullPano',
            name: 'callKrpano',
            arg: {
              name: 'skin_view_littleplanet'
            }
          }
        )()
      },
      UpdateCover(blob, url, event) {
        if (this.updateing) {
          return
        }
        this.updateing = true;
        if (blob) {
          let data = new FormData();
          //data.append('ArticleGUID', this.form.guid);
          data.append('file', blob, "Cover.jpg");
          var config = {
            url: url,
            method: 'POST',
            data: data,
          }
          this.$axios.request(config)
            .then((response) => {
              console.log('#', response)
              //this.$http.put('/Api/My/Panos/' + this.form.guid, this.form)
              //    .then(function (response) {
              //        this.Close()
              //        this.updateing = false;
              //    })
              //    .catch(function (error) {
              //        console.log(error)
              //        this.updateing = false;
              //    })
              //this.form.cover = response.data.cover
              this.$emit(event, response.data)
              this.updateing = false;
              this.$message({
                message: '保存成功',
                type: 'info',
                zIndex: 9999,
              })
              this.viewType = null
              this.$emit('updateComplete')
              //  this.resetPanoView()
            })
            .catch((error) => {
              console.error(error)
              this.updateing = false;
            })
        } else {
          console.error('缺少blob')
        }
      },
    },
  }
</script>
<style scoped>
</style>
